










































import { activeCallStore } from '@/store'
import { biChevronDown } from '@quasar/extras/bootstrap-icons'
import { Component, Prop, Vue } from 'vue-property-decorator'

type StartCallOption = {
  title: string,
  description: string,
  action: () => unknown,
}

@Component
export default class StartCallDropdownBtn extends Vue {
  @Prop({
    type: String,
    required: true,
  }) readonly jid!: string

  @Prop({
    type: Object,
    required: true,
  }) readonly phoneIcon!: Vue.Component

  @Prop({
    type: String,
    required: true,
  }) readonly tooltip!: string

  // icons signatures
  private expandIcon!: string

  private get silentVideoOption (): StartCallOption {
    return {
      title: this.$t('calls.callActionsBtn.startCall.silent.title').toString(),
      description: this.$t('calls.callActionsBtn.startCall.silent.description').toString(),
      action: () => this.call({ loud: false }),
    }
  }

  private get loudVideoOption (): StartCallOption {
    return {
      title: this.$t('calls.callActionsBtn.startCall.loud.title').toString(),
      description: this.$t('calls.callActionsBtn.startCall.loud.description').toString(),
      action: () => this.call({ loud: true }),
    }
  }

  private get options (): StartCallOption[] {
    return [
      this.silentVideoOption,
      this.loudVideoOption,
    ]
  }

  private created () {
    this.expandIcon = biChevronDown
  }

  private call (p: { loud: boolean }): void {
    const startBuzzing = p.loud
    activeCallStore.actions.startCall({ jid: this.jid, startBuzzing })
    window.goal('callControls', {
      type: `Начать звонок шапка чата ${startBuzzing ? 'громкий' : 'тихий'}`,
    })
  }
}
